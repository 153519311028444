import React, { useEffect, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Header from '@/components/Header';
import Footer from '@/components/Footer';

interface PageProps {
  title: string;
  description: string;
  image?: string;
  children?: React.ReactNode;
}

const defaultProps: Partial<PageProps> = {
  image: '/images/logo.png',
};

const Page: React.FC<PageProps> = ({ children, title, description, image }) => {
  const [newsArticlesData, setNewsArticlesData] = useState<{ title: string; url: string }[]>([]);

  const router = useRouter();
  // save the user origin to local storage
  useEffect(() => {
    const userOrigin = router.query.userOrigin;
    const originString = Array.isArray(userOrigin) ? userOrigin.join(',') : (userOrigin ?? '');

    if (userOrigin) {
      localStorage.setItem('userOrigin', originString);
    }
  }, [router.query.userOrigin]);

  // Fetch news files
  useEffect(() => {
    const fetchNewsArticles = async () => {
      try {
        const response = await fetch(`/api/getNewsFiles?locale=${router.locale}`);
        const data = (await response.json()) as { title: string; url: string }[];
        setNewsArticlesData(data);
      } catch (err) {
        console.error('Error loading news files');
      }
    };

    fetchNewsArticles();
  }, [router.locale]);

  const localesHead = router.locales?.map((locale) => {
    if (locale !== router.locale) {
      if (locale === router.defaultLocale) {
        return (
          <link
            key={locale}
            href={`${process.env.NEXT_PUBLIC_URL}${router.asPath}`}
            // For 'en' we can omit the region
            // As an example, for 'nl' we want to also include the region, outcome 'nl-nl'
            hrefLang={locale === 'en' ? locale : `${locale}-${locale}`}
            rel="alternate"
          ></link>
        );
      }
      return (
        <link
          key={locale}
          href={`${process.env.NEXT_PUBLIC_URL}/${locale}${router.asPath}`}
          hrefLang={locale === 'en' ? locale : `${locale}-${locale}`}
          rel="alternate"
        ></link>
      );
    }
  });

  return (
    <>
      <Head>
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <meta charSet="utf-8" />
        <title>{`${title} | Pro6PP`}</title>
        <meta content={description} name="description"></meta>
        <link
          rel="preload"
          as="image"
          imageSrcSet="/images/logo-600w.avif 600w, /images/logo-400w.avif 400w, /images/logo-200w.avif 200w"
          imageSizes="50vw"
        />
        {localesHead}
        {/* Open Graph, https://ogp.me */}
        <meta key="ogtitle" content={`${title} | Pro6PP`} property="og:title" />
        <meta key="ogdesc" content={description} property="og:description" />
        <meta key="ogurl" content={router.asPath} property="og:url" />
        <meta key="ogimage" content={image} property="og:image" />
        <meta key="ogsitename" content="Pro6PP" property="og:site_name" />
        <link href="/images/favicon.ico" rel="icon"></link>
      </Head>
      <Header />
      <main>{children}</main>
      <Footer newsArticlesData={newsArticlesData} />
    </>
  );
};

Page.defaultProps = defaultProps;

export default Page;
