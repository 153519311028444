import React, { type ChangeEventHandler, type EventHandler, type LegacyRef, useState } from 'react';
import ExclamationMark from '@/components/SVG/ExclamationMark';

export interface InputProps {
  label: string;
  type?: string;
  name: string;
  id?: string;
  value?: string | number | readonly string[] | undefined;
  classNames?: string[];
  disabled?: boolean;
  readOnly?: boolean;
  minLength?: number;
  maxLength?: number;
  required?: boolean;
  placeholder?: string;
  autoComplete?: string;
  autoCapitalize?: string;
  spellCheck?: boolean | 'true' | 'false';
  pattern?: string;
  requirementsError?: string;
  validationError?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: EventHandler<unknown>;
  onBlur?: EventHandler<unknown>;
  onKeyDown?: EventHandler<unknown>;
  optional?: boolean;
  innerRef?: LegacyRef<HTMLInputElement>;
  datatestid?: string;
}

export default function Input(props: InputProps) {
  const [_classNames] = useState(props.classNames || ['outline-red']);

  function onChange(evt: React.ChangeEvent<HTMLInputElement>) {
    props.onChange ? props.onChange(evt) : null;
  }

  return (
    <>
      <div className="w-full">
        <div className="flex justify-between">
          <label htmlFor={props.name}>{props.label}:</label>
          {props.optional ? (
            <span className="italic text-black text-opacity-50">(Optional)</span>
          ) : null}
        </div>
        <input
          type={props.type || 'text'}
          name={props.name}
          id={props.id || props.name}
          value={props.value}
          className={_classNames.join(' ')}
          readOnly={props.readOnly}
          disabled={props.disabled}
          minLength={props.minLength}
          maxLength={props.maxLength}
          required={props.optional ? false : props.required}
          placeholder={props.placeholder}
          autoComplete={props.autoComplete}
          autoCapitalize={props.autoCapitalize || 'false'}
          spellCheck={props.spellCheck || 'false'}
          pattern={props.pattern}
          onChange={onChange}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          onKeyDown={props.onKeyDown}
          ref={props.innerRef}
          data-test-id={props.id || props.name}
        />
        {props.validationError ? (
          <div className="mb-2 mt-1 flex gap-1 text-red" data-test-id="validation-error">
            <ExclamationMark />
            {props.validationError}
          </div>
        ) : null}
        {props.requirementsError ? (
          <div className="requirements">
            <ExclamationMark />
            {props.requirementsError}
          </div>
        ) : null}
      </div>
    </>
  );
}
