import React from 'react';

type AlertProps = {
  children: React.ReactNode;
  type: 'error' | 'success' | 'default';
};

const Alert = ({ children, ...props }: AlertProps) => {
  return (
    <div
      className={`rounded ${
        props.type === 'error' ? 'bg-red' : props.type === 'success' ? 'bg-green' : 'bg-blue-light'
      } sticky bottom-4 col-span-full px-4 py-4 text-white`}
      {...props}
    >
      {children}
    </div>
  );
};

export default Alert;
