const buildURLQuery = (data: unknown) => {
  const params = new URLSearchParams();
  Object.keys(data).forEach((key) => {
    if (data[key]) {
      params.append(key, data[key]);
    }
  });

  return params;
};

export default buildURLQuery;
