import { useTranslation } from 'next-i18next';
import Link from '@/modules/I18n/components/Link';

export default function Footer({
  newsArticlesData,
}: {
  newsArticlesData: { title: string; url: string }[];
}) {
  const { t } = useTranslation(['common']);

  return (
    <footer className="bg-blue text-white">
      <div className="inner">
        <nav
          className="mx-auto flex max-w-7xl flex-col gap-1 py-3 md:grid md:grid-cols-5"
          aria-label="Secondary navigation"
        >
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white">{t('company_name')}</div>
            <Link href="/" className="py-2 text-lg capitalize underline lg:p-0 lg:text-base">
              {t('home')}
            </Link>
            <Link
              href="/demo/address-validation/nl"
              className="py-2 text-lg capitalize underline lg:p-0 lg:text-base"
            >
              {t('nav_demos')}
            </Link>
            <Link
              href="/webshops"
              className="py-2 text-lg capitalize underline lg:p-0 lg:text-base"
            >
              {t('nav_webshops')}
            </Link>
            <Link href="/pricing" className="py-2 text-lg capitalize underline lg:p-0 lg:text-base">
              {t('nav_pricing')}
            </Link>
            <Link
              href={`/downloads/nl`}
              className="py-2 text-lg capitalize underline lg:p-0 lg:text-base"
            >
              {t('nav_downloads')}
            </Link>
            <Link href="/support" className="py-2 text-lg capitalize underline lg:p-0 lg:text-base">
              {t('nav_support')}
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white">{t('api')}</div>
            <Link
              href="/developer/changelog"
              className="py-2 text-lg underline lg:p-0 lg:text-base"
            >
              {t('changelog')}
            </Link>
            <Link
              href="/developer/deprecation"
              className="py-2 text-lg underline lg:p-0 lg:text-base"
            >
              {t('deprecation')}
            </Link>
            <Link href="/developer/roadmap" className="py-2 text-lg underline lg:p-0 lg:text-base">
              {t('roadmap')}
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white">{t('developer')}</div>
            <Link
              href="/webshops"
              className="py-2 text-lg capitalize underline lg:p-0 lg:text-base"
            >
              {t('webshop_integration')}
            </Link>
            <Link href="/developer/swagger" className="py-2 text-lg underline lg:p-0 lg:text-base">
              {t('v2_api')}
            </Link>
          </div>
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white">{t('news')}</div>
            {newsArticlesData?.map((data, index) => (
              <Link
                key={index}
                href={data.url}
                className="py-2 text-lg capitalize underline lg:p-0 lg:text-base"
              >
                {data.title.substring(0, 35) + '...'}
              </Link>
            ))}
          </div>
          <div className="flex flex-col">
            <div className="text-lg font-bold text-white">{t('service')}</div>
            <Link href="/privacy" className="py-2 text-lg underline lg:p-0 lg:text-base">
              {t('footer_privacy')}
            </Link>
            <Link href="/terms" className="py-2 text-lg underline lg:p-0 lg:text-base">
              {t('footer_terms')}
            </Link>
            <Link href="/demo/suggestions" className="py-2 text-lg underline lg:p-0 lg:text-base">
              {t('suggestions')}
            </Link>
          </div>
        </nav>
        <span className="my-6 block text-center">
          &copy; 2010-{new Date().getFullYear()} d-centralize geo B.V.
        </span>
      </div>
    </footer>
  );
}
