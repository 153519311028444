type Props = {
  suggestions: unknown;
  handleSelectLocation: unknown;
  shown: boolean;
  field: string;
  currentData: string;
};

export default function AutocompleteSuggestions({
  suggestions,
  handleSelectLocation,
  shown,
  field,
  currentData,
}: Props) {
  function selectLocation(location) {
    handleSelectLocation(location);
  }

  if (
    shown &&
    suggestions &&
    !suggestions.status &&
    !suggestions.error_id &&
    !(suggestions.length === 1 && suggestions[0][field] === currentData)
  ) {
    let previousStreetNumber = suggestions.length >= 1 ? [0].streetNumber : 0;
    const previousPremise = suggestions.length >= 1 ? [0].premise : 0;

    return (
      <ul
        data-test-id="suggestions"
        className="absolute left-0 top-[4.5rem] z-[5] w-full list-none overflow-y-auto rounded bg-white p-0 shadow"
      >
        {suggestions?.map((location: unknown, i: number) => {
          if (
            i !== 0 &&
            location.streetNumber === previousStreetNumber &&
            location.premise === previousPremise &&
            field === 'streetNumber'
          ) {
            return;
          }

          previousStreetNumber = location.streetNumber;

          return (
            <li
              role="presentation"
              key={location[field] + i}
              className="cursor-pointer p-2 transition-colors hover:bg-gray md:p-1"
              onMouseDown={() => {
                selectLocation(location);
              }}
            >
              {field === 'streetNumber'
                ? `${location.streetNumber} ${location.premise ? location.premise : ''}`
                : location[field]}
            </li>
          );
        })}
      </ul>
    );
  }
  return null;
}
