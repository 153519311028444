const mapHasAllValues = (map?: Map<unknown, unknown>, values?: unknown[], field?: string) => {
  let hasAll = true;
  if (!map || !values) return hasAll;
  values.map((value: unknown) => {
    if (field) {
      if (!map.has(value[field])) {
        hasAll = false;
      }
    } else if (!map.has(value)) {
      hasAll = false;
    }
  });
  return hasAll;
};

export default mapHasAllValues;
