import useSWR from 'swr';
import buildURLQuery from '@/utils/buildURLQuery';
import mapHasAllValues from '@/utils/mapHasAllValues';
import React, { type EventHandler, useState } from 'react';
import Input, { type InputProps } from '@/components/Input';
import AutocompleteSuggestions from '@/components/AutocompleteSuggestions';

interface GeoInputProps extends InputProps {
  suggest?: boolean;
  onSelectSuggestionHandler?: EventHandler<unknown>;
  country?: string;
  requiredInputsToSuggest?: Array<string>;
  inputs?: Map<string, string>;
  handleResponse?: (data: Map<string, undefined>) => void;
}

export default function GeoInput(props: GeoInputProps) {
  function selectSuggestion(evt: unknown) {
    props.onSelectSuggestionHandler ? props.onSelectSuggestionHandler(evt) : null;
  }

  const [suggestionsShown, setSuggestionsShown] = useState(false);
  const handleResponse: ((data: Map<string, undefined>) => void) | undefined = props.handleResponse;

  const authKey = process.env.NEXT_PUBLIC_AUTH_KEY;

  const { data, isLoading } = useSWR(
    suggestionsShown &&
      props.country &&
      props.name &&
      props.inputs &&
      mapHasAllValues(props.inputs, props.requiredInputsToSuggest)
      ? `/suggest/${props.country?.toLowerCase()}/${props.name}?${buildURLQuery({
          authKey,
          ...Object.fromEntries(props.inputs),
        })}`
      : null,
    {
      dedupingInterval: 10000, // don't send the same request more than once per 10 seconds
      onSuccess: (data, key) => {
        if (data.length >= 1) {
          data[0].key = key; // Url used for checking streetNumber
          handleResponse ? handleResponse(new Map(Object.entries(data[0]))) : null;
        }
      },
    },
  );

  return (
    <>
      <Input
        onFocus={props.suggest ? () => setSuggestionsShown(true) : undefined}
        onBlur={props.suggest ? () => setSuggestionsShown(false) : undefined}
        onKeyDown={(e: KeyboardEvent) =>
          props.suggest && e.key === 'Escape' ? setSuggestionsShown(false) : null
        }
        {...props}
      />
      {props.suggest ? (
        !isLoading ? (
          <AutocompleteSuggestions
            suggestions={data}
            handleSelectLocation={(e: Event) => {
              selectSuggestion(e);
            }}
            shown={suggestionsShown}
            field={props.name}
            currentData={props.value?.toString() || ''}
          ></AutocompleteSuggestions>
        ) : null
      ) : null}
    </>
  );
}

GeoInput.displayName = 'GeoInput';
