import { type NextRouter } from 'next/router';

const getCanonical = (page: string, router: NextRouter) => {
  // remove parameters and hashes
  page = page.split('#')[0].split('?')[0];

  return `${process.env.NEXT_PUBLIC_URL}${
    router.locale === router.defaultLocale ? '' : `/${router.locale}`
  }${page === '/' ? '' : page}`;
};

export default getCanonical;
